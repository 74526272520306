import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Exception404 = () => (
  <div>Exception 404</div>
);

export default withStyles(styles)(Exception404);
