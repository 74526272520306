export default theme => ({
  operatorWrapper: {
    textAlign: 'right',
    paddingRight: '0 !important',
  },
  toolbar: {
    paddingLeft: theme.spacing.unit * 3,
  },
  margin: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
});
export const infoModalStyles = theme => ({
  content: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
