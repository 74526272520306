import { colors } from 'themes/variables';
import { successButtonMixin } from 'themes/mixins';

export default theme => ({
  operatorWrapper: {
    textAlign: 'right',
    paddingRight: '0 !important',
  },
  operator: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  editIcon: {
    color: colors.focus,
  },
  deleteIcon: {
    color: colors.secondary,
    marginLeft: theme.spacing.unit,
  },
  create: {
    textAlign: 'right',
    paddingRight: '0 !important',
  },
  successButton: {
    ...successButtonMixin(),
  },
});
