import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const ProductAttribute = () => (
  <div>Product Attribute</div>
);

export default withStyles(styles)(ProductAttribute);

