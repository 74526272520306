import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import TableService from 'apis/services/table';

export const initialState = {
  results: [],
};

const FETCH_TABLE = Symbol('FETCH_TABLE');
const FETCH_TABLE_SUCCESS = Symbol('FETCH_TABLE_SUCCESS');

export const fetchTable = params => ({ type: FETCH_TABLE, payload: params });
export const fetchTableSuccess = response => ({
  type: FETCH_TABLE_SUCCESS,
  payload: response,
});

export const fetchTableEpic = (action$, store$) =>
  action$.ofType(FETCH_TABLE).filter((action, index) => {
    const table = store$.value.table;
    if (index === 0) {
      return true;
    }
    if (isEmpty(table.results)) {
      return false;
    }
    return true;
  }).mergeMap((action) => {
    const table = store$.value.table;
    if (isEmpty(table.results)) {
      return Observable.fromPromise(TableService.fetch(action.payload))
        .map(response => fetchTableSuccess(response));
    }
    return Observable.of(fetchTableSuccess({ data: table }));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TABLE_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
