export default theme => ({
  section: {
    display: 'flex',
    width: '100%',
    minWidth: '60vw',
  },
  gap: {
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
  },
});
