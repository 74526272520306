import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import BuffetService from 'apis/services/buffet';
import { getDefaultPaginationStatus } from 'status/schemas/pagination';

export const initialState = getDefaultPaginationStatus();

const FETCH_BUFFET = Symbol('FETCH_BUFFET');
const FETCH_BUFFET_SUCCESS = Symbol('FETCH_BUFFET_SUCCESS');

export const fetchBuffet = params => ({ type: FETCH_BUFFET, payload: params });
export const fetchBuffetSuccess = response => ({
  type: FETCH_BUFFET_SUCCESS,
  payload: response,
});

export const fetchBuffetEpic = (action$, store$) =>
  action$.ofType(FETCH_BUFFET).mergeMap((action) => {
    const buffet = store$.value.buffet;
    if (isEmpty(buffet.results)) {
      return Observable.fromPromise(BuffetService.fetch(action.payload))
        .map(response => fetchBuffetSuccess(response));
    }
    return Observable.of(fetchBuffetSuccess({ data: buffet }));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUFFET_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
