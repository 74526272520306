import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { formatCurrency } from 'utils/priceTools';
import { getMaxPromotion } from 'utils/cartItemTools';

import styles from './styles';

function getItemPromotionValue(item, created) {
  const target = getMaxPromotion(
    get(item, 'ordermerchandisepromotion_set', []),
    moment(created).toDate(),
  );
  const detail = get(target, 'detail', { value: 1 });
  return detail.value;
}

class OrderItemTable extends React.PureComponent {
  render() {
    const { skuset, created } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>Count</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            skuset.map(item => (
              <TableRow key={item.id}>
                <TableCell>
                  { item.name }
                </TableCell>
                <TableCell>
                  { `${100 - (getItemPromotionValue(item, created) * 100)}%` }
                </TableCell>
                <TableCell>
                  {
                    formatCurrency(
                      getItemPromotionValue(item, created) * item.price,
                    )
                  }
                  {
                    getItemPromotionValue(item, created) !== 1 &&
                    <small>{` (Original ${formatCurrency(item.price)})`}</small>
                  }
                </TableCell>
                <TableCell>
                  { item.count }
                </TableCell>
                <TableCell>
                  {
                    formatCurrency(item.price * item.count * getItemPromotionValue(item, created))
                  }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

OrderItemTable.propTypes = {
  // classes: PropTypes.instanceOf(Object).isRequired,
  skuset: PropTypes.arrayOf(PropTypes.shape({})),
  created: PropTypes.string,
};

OrderItemTable.defaultProps = {
  skuset: [],
  created: (new Date()).toDateString(),
};

export default withStyles(styles)(OrderItemTable);
