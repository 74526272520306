import React from 'react';
import ReactDom from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'themes/customization';
import { Provider } from 'react-redux';
import store from 'status/store';
import { message } from 'antd';
import RouteComponents from 'routes/RouteComponents';

message.config({
  top: 72,
  maxCount: 3,
});

async function startApp() {
  const root = document.getElementById('root');
  ReactDom.render(
    (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />
            <RouteComponents />
          </React.Fragment>
        </MuiThemeProvider>
      </Provider>
    ),
    root,
  );
}

startApp();
