import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import MerchandisePromotionService from 'apis/services/merchandisePromotion';
import { getDefaultPaginationStatus } from 'status/schemas/pagination';

export const initialState = getDefaultPaginationStatus();

const FETCH_MERCHANDISE_PROMOTION = Symbol('FETCH_MERCHANDISE_PROMOTION');
const FETCH_MERCHANDISE_PROMOTION_SUCCESS = Symbol('FETCH_MERCHANDISE_PROMOTION_SUCCESS');

export const fetchMerchandisePromotion = params => ({
  type: FETCH_MERCHANDISE_PROMOTION,
  payload: params,
});
export const fetchMerchandisePromotionSuccess = response => ({
  type: FETCH_MERCHANDISE_PROMOTION_SUCCESS,
  payload: response,
});

export const fetchMerchandisePromotionEpic = (action$, store$) =>
  action$.ofType(FETCH_MERCHANDISE_PROMOTION).mergeMap((action) => {
    const merchandisePromotion = store$.value.merchandisePromotion;
    if (isEmpty(merchandisePromotion.results)) {
      return Observable.fromPromise(MerchandisePromotionService.fetch(action.payload))
        .map(response => fetchMerchandisePromotionSuccess(response));
    }
    return Observable.of(fetchMerchandisePromotionSuccess({ data: merchandisePromotion }));
  });


export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANDISE_PROMOTION_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
