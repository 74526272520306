import { withStyles } from '@material-ui/core/styles';
import { renderRoutes } from 'react-router-config';

import styles from './styles';

const System = ({ route }) => renderRoutes(route.routes);


export default withStyles(styles)(System);
export { default as SystemBill } from './Bill';
export { default as SystemPlugin } from './Plugin';
export { default as SystemSupplier } from './Supplier';
