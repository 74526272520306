import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

class CarryOutOrderService {
  static fetch(originParams) {
    const params = {
      ...originParams,
    };
    return request.get(`${apiUrlV1}/carry_out_order/`, { params });
  }
}

export default CarryOutOrderService;
