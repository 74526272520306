import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const SystemSupplier = () => (
  <div>System Supplier</div>
);

export default withStyles(styles)(SystemSupplier);

