import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const SystemBill = () => (
  <div>System Bill</div>
);

export default withStyles(styles)(SystemBill);

