import React from 'react';
import { renderRoutes } from 'react-router-config';
import { ConnectedRouter } from 'connected-react-router';
import { HashRouter } from 'react-router-dom';
import Dashboard from 'views/Dashboard';
import Login from 'views/Login';
import Term from 'views/Term';
import SetPassword from 'views/Password';
import Product, { ProductManagement, ProductCategory, ProductAttribute, ProductAttachAttribute, ProductExtra } from 'views/Product';
import Promotion, { PromotionMerchandise } from 'views/Promotion';
import Order, { WebOrder, LocalOrder, CarryOutOrder, OrderStatistics } from 'views/Order';
import Customer from 'views/Customer';
import Buffet from 'views/Buffet';
import Shop, { ShopBranch, ShopSubtopic, ShopAD, ShopHardware, ShopTable, ShopWebOrder } from 'views/Shop';
import System, { SystemBill, SystemPlugin, SystemSupplier } from 'views/System';
import Exception, { Exception400, Exception404, Exception500 } from 'views/Exception';
import BasicLayout from 'layouts/BasicLayout';
import routeNames from './routeNames';
import history from '../history';

// see here for more
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: routeNames.login,
    component: Login,
    exact: true,
  },
  {
    path: routeNames.term,
    component: Term,
    exact: true,
  },
  {
    path: routeNames.setPassword,
    component: SetPassword,
    exact: true,
  },
  {
    path: routeNames.admin,
    component: BasicLayout,
    routes: [
      {
        path: routeNames.dashboard,
        component: Dashboard,
        exact: true,
      },
      {
        path: routeNames.product,
        component: Product,
        routes: [
          {
            path: routeNames.productManagement,
            component: ProductManagement,
            exact: true,
          },
          {
            path: routeNames.productCategory,
            component: ProductCategory,
            exact: true,
          },
          {
            path: routeNames.productAttribute,
            component: ProductAttribute,
            exact: true,
          },
          {
            path: routeNames.productAttachAttribute,
            component: ProductAttachAttribute,
            exact: true,
          },
          {
            path: routeNames.productExtra,
            component: ProductExtra,
            exact: true,
          },
        ],
      },
      {
        path: routeNames.promotion,
        component: Promotion,
        routes: [
          {
            path: routeNames.promotionMerchandise,
            component: PromotionMerchandise,
            exact: true,
          },
        ],
      },
      {
        path: routeNames.buffet,
        component: Buffet,
      },
      {
        path: routeNames.order,
        component: Order,
        routes: [
          {
            path: routeNames.orderWeb,
            component: WebOrder,
            exact: true,
          },
          {
            path: routeNames.orderLocal,
            component: LocalOrder,
            exact: true,
          },
          {
            path: routeNames.orderCarryOut,
            component: CarryOutOrder,
            exact: true,
          },
          {
            path: routeNames.orderStatistics,
            component: OrderStatistics,
            exact: true,
          },
        ],
      },
      {
        path: routeNames.customer,
        component: Customer,
        exact: true,
      },
      {
        path: routeNames.shop,
        component: Shop,
        routes: [
          {
            path: routeNames.shopBranch,
            component: ShopBranch,
            exact: true,
          },
          {
            path: '/shop/subtopic',
            component: ShopSubtopic,
            exact: true,
          },
          {
            path: '/shop/ad',
            component: ShopAD,
            exact: true,
          },
          {
            path: '/shop/hardware',
            component: ShopHardware,
            exact: true,
          },
          {
            path: '/shop/table',
            component: ShopTable,
            exact: true,
          },
          {
            path: '/shop/web-order',
            component: ShopWebOrder,
            exact: true,
          },
        ],
      },
      {
        path: '/system',
        component: System,
        routes: [
          {
            path: '/system/bill',
            component: SystemBill,
            exact: true,
          },
          {
            path: '/system/plugin',
            component: SystemPlugin,
            exact: true,
          },
          {
            path: '/system/supplier',
            component: SystemSupplier,
            exact: true,
          },
        ],
      },
      {
        path: '/exception',
        component: Exception,
        routes: [
          {
            path: '/exception/400',
            component: Exception400,
            exact: true,
          },
          {
            path: '/exception/404',
            component: Exception404,
            exact: true,
          },
          {
            path: '/exception/500',
            component: Exception500,
            exact: true,
          },
        ],
      },
    ],
  },
];

const RouteComponents = () => (
  <ConnectedRouter history={history}>
    <HashRouter>
      {renderRoutes(routes)}
    </HashRouter>
  </ConnectedRouter>
);

export default RouteComponents;
