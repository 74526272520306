import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const ShopWebOrder = () => (
  <div>123123</div>
);

export default withStyles(styles)(ShopWebOrder);
