import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { formatCurrency } from 'utils/priceTools';

import styles from './styles';

class OrderExtraTable extends React.PureComponent {
  render() {
    const { skuset } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell>Extra</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>Count</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            skuset.map(item => (
              <React.Fragment key={item.id}>
                {
                  get(item, 'orderextra_set', []).map((extra, index) => (
                    <TableRow key={extra.id}>
                      {
                        index === 0 &&
                        <TableCell
                          rowSpan={item.orderextra_set.length}
                        >
                          { item.name }
                        </TableCell>
                      }
                      <TableCell>{ extra.name }</TableCell>
                      <TableCell>{ formatCurrency(extra.price) }</TableCell>
                      <TableCell>{ extra.count }</TableCell>
                      <TableCell>{ formatCurrency(extra.price * extra.count) }</TableCell>
                    </TableRow>
                  ))
                }
              </React.Fragment>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

OrderExtraTable.propTypes = {
  // classes: PropTypes.instanceOf(Object).isRequired,
  skuset: PropTypes.arrayOf(PropTypes.shape({})),
};

OrderExtraTable.defaultProps = {
  skuset: [],
};
export default withStyles(styles)(OrderExtraTable);
