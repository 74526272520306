import React, { Fragment } from 'react';
import { isEmpty, omit, get } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { formatCurrency } from 'utils/priceTools';
import { getSaleAtPlatform } from 'utils/dataTransfer';
import { MerchandiseFilter, filterMerchandise } from 'components/containers/forms/MerchandiseFilter';

import styles from './styles';

class MerchandiseSelector extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    const { product } = props;
    return {
      ...state,
      renderItems: filterMerchandise(
        get(product, 'results', []),
        state.filterValues,
      ),
    };
  }

  constructor() {
    super();
    this.state = {
      filterValues: {
        id: '',
        name: '',
        category: '',
        platform: '',
      },
      selected: {},
      renderItems: {},
    };
  }

  onFilter = (values) => {
    this.setState({
      filterValues: values,
    });
  }

  handleSelect = (e) => {
    const id = e.target.value;
    const { selected } = this.state;
    const { onChange } = this.props;
    if (isEmpty(selected[id])) {
      this.setState({
        selected: {
          ...selected,
          ...{ [id]: true },
        },
      }, () => onChange(selected));
    } else {
      this.setState({
        selected: omit(selected, id),
      }, () => onChange(selected));
    }
  }

  render() {
    const { selected, renderItems } = this.state;

    const results = renderItems;

    return (
      <Fragment>
        <MerchandiseFilter
          onSubmit={this.onFilter}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Original Price</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Platform</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    onClick={this.handleSelect}
                    checked={selected[row.id]}
                    value={row.id}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{formatCurrency(row.price)} kr.</TableCell>
                <TableCell align="right">{row.store_category.name}</TableCell>
                <TableCell align="right">{getSaleAtPlatform(row.sale_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

MerchandiseSelector.propTypes = {
  // classes: PropTypes.instanceOf(Object).isRequired,
  product: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      sale_at: PropTypes.string,
    })),
  }).isRequired,
  onChange: PropTypes.func,
};

MerchandiseSelector.defaultProps = {
  onChange: false,
};

export default withStyles(styles)(MerchandiseSelector);
