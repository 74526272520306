
export default theme => ({
  wrapper: {
    display: 'flex',
    // justifyContent: 'space-between',
  },
  inputWrapper: {
    minWidth: 200,
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  item: {
    paddingRight: theme.spacing.unit * 2,
    // padding: theme.spacing.unit * 2
  },
  buttonWrapper: {
    display: 'flex',
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
  },
});
