import React, { Fragment } from 'react';
import { get, isEmpty, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fetchCarryOutOrder } from 'status/partial/carryOutOrder';
import { getPaymentStatus } from 'utils/dataTransfer';
import { formatDatetime } from 'utils/datetimeTools';
import { formatCurrency, getNetOrderAmount } from 'utils/priceTools';
import { CarryOutOrderFilter } from 'components/containers/forms/OrderFilters';
import InfoModal from './InfoModal';
import styles from './styles';

class CarryOutOrder extends React.PureComponent {
  state = {
    currentRow: {},
    openDetail: false,
  }

  componentDidMount() {
    const { carryOutOrder } = this.props;
    this.fetchData(get(carryOutOrder, 'current_page', 1));
  }

  handleChangePage = (e, targetIndex) => {
    const { params } = this.props;
    const target = targetIndex + 1;
    this.fetchData(
      target,
      {
        ...params,
        forceRefresh: true,
      },
    );
  }

  handleRowClick = (row) => {
    this.setState({
      currentRow: row,
      openDetail: true,
    });
  }

  handleDetailClose = () => {
    this.setState({
      openDetail: false,
    });
  }

  handleFilter = (values) => {
    const { carryOutOrder } = this.props;
    this.fetchData(
      get(carryOutOrder, 'current_page', 1),
      {
        forceRefresh: true,
        ...values,
      },
    );
  }

  fetchData = (page = 1, params = {}) => {
    const { store } = this.props;
    this.props.fetchCarryOutOrder({
      store: store.id,
      page,
      ...params,
    });
  }

  render() {
    const { openDetail, currentRow } = this.state;
    const { classes, carryOutOrder, params } = this.props;

    const results = get(carryOutOrder, 'results', []);
    return (
      <Fragment>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">
              Carry Out Order
            </Typography>
          </Toolbar>
          <div className={classes.toolbar}>
            <CarryOutOrderFilter
              onSubmit={this.handleFilter}
              initialValues={params}
              destroyOnUnmount={false}
              paymentWay
              paymentStatus
            />
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">Order Number</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Staff</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Payment Way</TableCell>
                <TableCell align="right">Payment Status</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(row => (
                <TableRow
                  key={row.id}
                  onClick={() => this.handleRowClick(row)}
                  hover
                >
                  <TableCell align="right">{row.order_code}</TableCell>
                  <TableCell align="right">{formatCurrency(getNetOrderAmount(row))}</TableCell>
                  <TableCell align="right">
                    { !isEmpty(row.staff_name) ? row.staff_name : 'Unknow Staff' }
                  </TableCell>
                  <TableCell align="right">{formatDatetime(row.created)}</TableCell>
                  <TableCell align="right">{upperFirst(row.payment_type)}</TableCell>
                  <TableCell align="right">{getPaymentStatus(row.pay_status)}</TableCell>
                  <TableCell align="right">{upperFirst(row.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={carryOutOrder.count}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={carryOutOrder.current_page - 1}
            onChangePage={this.handleChangePage}
          />
        </Paper>
        <InfoModal
          open={openDetail}
          row={currentRow}
          handleClose={this.handleDetailClose}
        />
      </Fragment>
    );
  }
}

CarryOutOrder.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  carryOutOrder: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchCarryOutOrder: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
};

function mapStateToProps({ carryOutOrder, store, form }) {
  return {
    carryOutOrder,
    store,
    params: get(form, 'carryOutOrderFilter.values', {}),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCarryOutOrder: bindActionCreators(fetchCarryOutOrder, dispatch),
  };
}

const CarryOutOrderWidthStyle = withStyles(styles)(CarryOutOrder);
export default connect(mapStateToProps, mapDispatchToProps)(CarryOutOrderWidthStyle);

