import React from 'react';
import PropTypes from 'prop-types';
import { isArray, keys } from 'lodash';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

function MultipleSelectorField({ input, items, label }) {
  const values = isArray(input.value) ? input.value : [];
  return (
    <FormControl
      margin="normal"
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        input={<Input />}
        renderValue={selected => selected.map(key => items[key]).join(', ')}
        {...input}
        value={values}
      >
        {keys(items).map(key => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={input.value.indexOf(key) > -1} />
            <ListItemText primary={items[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultipleSelectorField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.shape(),
};

MultipleSelectorField.defaultProps = {
  items: {},
};

export default MultipleSelectorField;
