import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import MerchandisePromotionEditor from 'components/containers/forms/MerchandisePromotionEditor';
import { infoModalStyles as styles } from './styles';

class EditorModal extends React.PureComponent {
  handleClose = (e, row) => {
    this.props.handleClose(e, row);
  }

  handleSubmit = (values) => {
    this.props.handleSave(values);
  }

  render() {
    const { open, row, classes, dispatch } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
      >
        <DialogTitle>Promotiom Name: {row.name}</DialogTitle>
        <DialogContent className={classes.content}>
          <MerchandisePromotionEditor
            onSubmit={this.handleSubmit}
            initialValues={{
              name: row.name,
              value: get(row, 'detail.value'),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cencel
          </Button>
          <Button
            onClick={() => dispatch(submit('merchandisePromotionEditor'))}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditorModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
};

EditorModal.defaultProps = {
  open: false,
  handleClose: () => null,
  handleSave: () => null,
};

export default connect()(withMobileDialog()(withStyles(styles)(EditorModal)));
