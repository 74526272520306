import category from './category';
import product from './product';
import auth from './auth';
import staff from './staff';
import store from './store';
import buffet from './buffet';
import merchandisePromotion from './merchandisePromotion';
import extra from './extra';
import netOrder from './netOrder';
import localOrder from './localOrder';
import carryOutOrder from './carryOutOrder';
import table from './table';

import ui from './ui';

export default {
  category,
  product,
  auth,
  staff,
  store,
  buffet,
  merchandisePromotion,
  extra,
  netOrder,
  localOrder,
  carryOutOrder,
  table,
  ui,
};
