import { withStyles } from '@material-ui/core/styles';
import { renderRoutes } from 'react-router-config';

import styles from './styles';

const Order = ({ route }) => renderRoutes(route.routes);

export default withStyles(styles)(Order);
export { default as WebOrder } from './Web';
export { default as LocalOrder } from './Local';
export { default as CarryOutOrder } from './CarryOut';
export { default as OrderStatistics } from './Statistics';
