import { get, isEmpty } from 'lodash';

export const saleAtMap = {
  all: 'Both',
  online: 'Web',
  offline: 'Local',
};

export function getSaleAtPlatform(saleAt) {
  return get(saleAtMap, saleAt, 'None');
}

export const paymentStatusMap = {
  waiting: 'Non-Payment',
  paid: 'Paid',
};

export function getPaymentStatus(status) {
  return get(paymentStatusMap, status, 'Non-Payment');
}

export const deliveryTypeMap = {
  pick_up: 'Pick Up',
  delivery: 'Delivery',
};

export function getDeliveryType(type) {
  return get(deliveryTypeMap, type, '');
}

export function addMerchandisesAsKey(merchandises, list, keyName = 'merchandises') {
  const result = [];
  if (isEmpty(list)) {
    return result;
  }
  const kv = {};
  list.forEach((item, index) => {
    kv[item.id] = index;
    result.push({
      ...item,
      [keyName]: [],
    });
  });
  if (isEmpty(merchandises) && !isEmpty(list)) {
    return result;
  }
  merchandises.forEach((merchandise) => {
    const index = kv[merchandise.store_category.id];
    result[index][keyName].push(merchandise);
  });
  return result;
}
