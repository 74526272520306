import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const OrderStatistics = () => (
  <div>Order Statistics</div>
);

export default withStyles(styles)(OrderStatistics);
