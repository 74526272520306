
export default theme => ({
  wrapper: {
    display: 'flex',
  },
  inputWrapper: {
    minWidth: 200,
  },
  button: {
    marginTop: theme.spacing.unit * 3.5,
    marginBottom: theme.spacing.unit,
    minWidth: 200,
  },
  item: {
    paddingRight: theme.spacing.unit * 2,
  },
});
