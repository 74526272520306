import { flow } from 'lodash';
import axios from 'axios';
import {
  errorInterceptor,
  unauthorizedInterceptor,
  widthToken,
  setStateLoadingHide,
  setStateLoadingShow,
} from './interceptors';

const request = axios.create();
request.interceptors.request.use(setStateLoadingShow);
request.interceptors.response.use(
  setStateLoadingHide,
  flow([setStateLoadingHide, errorInterceptor]),
);


const requestWidthAuth = axios.create();
requestWidthAuth.interceptors.request.use(flow([setStateLoadingShow, widthToken]));
requestWidthAuth.interceptors.response.use(
  setStateLoadingHide,
  flow([setStateLoadingHide, unauthorizedInterceptor]),
);

export {
  request,
  requestWidthAuth,
};
