import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import ExtraService from 'apis/services/extra';

export const initialState = {
  results: [],
};

const FETCH_EXTRA = Symbol('FETCH_EXTRA');
const FETCH_EXTRA_SUCCESS = Symbol('FETCH_EXTRA_SUCCESS');

export const fetchExtra = params => ({ type: FETCH_EXTRA, payload: params });
export const fetchExtraSuccess = response => ({
  type: FETCH_EXTRA_SUCCESS,
  payload: response,
});

export const fetchExtraEpic = (action$, store$) =>
  action$.ofType(FETCH_EXTRA).mergeMap((action) => {
    const extra = store$.value.extra;
    if (isEmpty(extra.results)) {
      return Observable.fromPromise(ExtraService.fetch(action.payload))
        .map(response => fetchExtraSuccess(response));
    }
    return Observable.of(fetchExtraSuccess({ data: extra }));
  });


export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXTRA_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
