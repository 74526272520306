import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

function DateTimeField({ input, label }) {
  const value = isEmpty(input.value) ? null : input.value;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        {...input}
        value={value}
        fullWidth
        clearable
        margin="normal"
        label={label}
      />
    </MuiPickersUtilsProvider>
  );
}

DateTimeField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
};

DateTimeField.defaultProps = {
  label: 'Pick a time',
};

export default DateTimeField;
