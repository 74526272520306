import React, { Fragment } from 'react';
import { get, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fetchNetOrder } from 'status/partial/netOrder';
import { getPaymentStatus } from 'utils/dataTransfer';
import { formatDatetime } from 'utils/datetimeTools';
import { formatCurrency, getNetOrderAmount } from 'utils/priceTools';
import { paginationPropTypes } from 'status/schemas/pagination';
import { WebOrderFilter } from 'components/containers/forms/OrderFilters';
import InfoModal from './InfoModal';
import styles from './styles';

class NetOrder extends React.PureComponent {
  state = {
    currentRow: {},
    openDetail: false,
  }

  componentDidMount() {
    const { netOrder } = this.props;
    this.fetchData(get(netOrder, 'current_page', 1));
  }

  handleChangePage = (e, targetIndex) => {
    const { params } = this.props;
    const target = targetIndex + 1;
    this.fetchData(
      target,
      {
        ...params,
        forceRefresh: true,
      },
    );
  }

  handleRowClick = (row) => {
    this.setState({
      currentRow: row,
      openDetail: true,
    });
  }

  handleDetailClose = () => {
    this.setState({
      openDetail: false,
    });
  }

  handleFilter = (values) => {
    const { netOrder } = this.props;
    this.fetchData(
      get(netOrder, 'current_page', 1),
      {
        forceRefresh: true,
        ...values,
      },
    );
  }

  fetchData = (page = 1, params = {}) => {
    const { store } = this.props;
    this.props.fetchNetOrder({
      page,
      store: store.id,
      ...params,
    });
  }

  render() {
    const { openDetail, currentRow } = this.state;
    const { classes, netOrder, params } = this.props;

    const results = get(netOrder, 'results', []);
    return (
      <Fragment>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">
              Web Order
            </Typography>
          </Toolbar>
          <div className={classes.toolbar}>
            <WebOrderFilter
              onSubmit={this.handleFilter}
              initialValues={params}
              destroyOnUnmount={false}
              paymentWay
              paymentStatus
            />
          </div>
          <br />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">Order Number</TableCell>
                <TableCell align="right">Customer</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Payment Way</TableCell>
                <TableCell align="right">Payment Status</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(row => (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => this.handleRowClick(row)}
                >
                  <TableCell align="right">{row.order_code}</TableCell>
                  <TableCell align="right">{row.contact_name}</TableCell>
                  <TableCell align="right">
                    {formatCurrency(getNetOrderAmount(row) + row.delivery_amount)}
                  </TableCell>
                  <TableCell align="right">{formatDatetime(row.created)}</TableCell>
                  <TableCell align="right">{upperFirst(row.payment_type)}</TableCell>
                  <TableCell align="right">{getPaymentStatus(row.pay_status)}</TableCell>
                  <TableCell align="right">{upperFirst(row.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={netOrder.count}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={netOrder.current_page - 1}
            onChangePage={this.handleChangePage}
          />
        </Paper>
        <InfoModal
          open={openDetail}
          row={currentRow}
          handleClose={this.handleDetailClose}
        />
      </Fragment>
    );
  }
}

NetOrder.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  netOrder: paginationPropTypes.isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchNetOrder: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
};

function mapStateToProps({ netOrder, store, form }) {
  return {
    netOrder,
    store,
    params: get(form, 'webOrderFilter.values', {}),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNetOrder: bindActionCreators(fetchNetOrder, dispatch),
  };
}

const NetOrderWidthStyle = withStyles(styles)(NetOrder);
export default connect(mapStateToProps, mapDispatchToProps)(NetOrderWidthStyle);
