import { curry } from 'lodash';
import { colors, monochrome } from 'themes/variables';
import { screens } from './variables';

export const breakPoint = curry((size, content) => {
  const key = `@media (min-width: ${size}px)`;
  return {
    [key]: {
      ...content,
    },
  };
});

export const mediaMedium = breakPoint(screens.md);
export const mediaLarge = breakPoint(screens.lg);
export const mediaXlarge = breakPoint(screens.xl);

export const controlOverflow = function controlOverflow() {
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };
};

export const wrapper = function wrapper() {
  return {
    minHeight: '100vh',
  };
};

export const linkMixin = function linkMixin(options = { isBlock: true }) {
  const { isBlock } = options;
  let result = {
    color: colors.primary,
    textDecoration: 'none',
  };
  if (isBlock) {
    result = {
      ...result,
      display: 'block',
    };
  }
  return result;
};

export const successButtonMixin = function successButton() {
  return {
    color: monochrome.white,
    backgroundColor: colors.success,
    '&:hover': {
      backgroundColor: colors.success,
    },
  };
};
