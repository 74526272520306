import { first, findIndex, isEmpty, get, isEqual } from 'lodash';
import moment from 'moment';
import { isDatetimeInWeeklySchedule, isTargetDatetimeInRange } from 'utils/datetimeTools';

export function findTargetPromotion(spuID, promotion, targetTime) {
  const targetPromotionList = promotion.filter(
    item => findIndex(item.joined_merchandises, id => id === spuID) >= 0,
  ).filter(
    item => isTargetDatetimeInRange(
      targetTime,
      moment(item.start_time).toDate(),
      moment(item.end_time).toDate(),
    ),
  ).filter(
    item => isDatetimeInWeeklySchedule(targetTime, item.active_weekly_schedule),
  );
  const targetPromotion = first(targetPromotionList.sort(
    (x, y) => x.detail.value - y.detail.value,
  ));
  if (isEmpty(targetPromotion)) {
    return {};
  }
  return targetPromotion;
}

export function getMaxPromotion(promotion, targetTime) {
  const targetPromotionList = promotion.filter(
    item => isTargetDatetimeInRange(
      targetTime,
      moment(item.start_time).toDate(),
      moment(item.end_time).toDate(),
    ),
  ).filter(
    item => isDatetimeInWeeklySchedule(targetTime, item.active_weekly_schedule),
  );
  const targetPromotion = first(targetPromotionList.sort(
    (x, y) => x.detail.value - y.detail.value,
  ));
  if (isEmpty(targetPromotion)) {
    return {};
  }
  return targetPromotion;
}

export function isShowCartItemDetail(cartItem) {
  const sku = get(cartItem, 'sku', {});
  const properties = get(sku, 'properties', [{}]);
  const extras = get(cartItem, 'extras', []);
  const hasDefaultProp = findIndex(properties, prop => isEqual(prop, {
    key: 'default',
    value: 'default',
  })) >= 0;
  const isDefaultProp = hasDefaultProp && properties.length === 1;
  if (isEmpty(extras) && isDefaultProp) {
    return false;
  }
  return true;
}

