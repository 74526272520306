import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { formatCurrency } from 'utils/priceTools';
import { getSaleAtPlatform } from 'utils/dataTransfer';
import { fetchProduct } from 'status/partial/product';
import { MerchandiseFilter, filterMerchandise } from 'components/containers/forms/MerchandiseFilter';

import styles from './styles';

class ProductManagement extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      filterValues: {
        id: '',
        name: '',
        category: '',
        platform: '',
      },
    };
  }

  componentDidMount() {
    const { store } = this.props;
    this.props.fetchProduct({
      store: store.id,
    });
  }

  onFilter = (values) => {
    this.setState({
      filterValues: values,
    });
  }

  render() {
    const { classes, product } = this.props;
    const { filterValues } = this.state;

    const results = isEmpty(product) ? [] : filterMerchandise(product.results, filterValues);


    return (
      <Fragment>
        <header className={classes.header}>
          <MerchandiseFilter
            onSubmit={this.onFilter}
          />
        </header>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Original Price</TableCell>
              <TableCell align="right">Sales Promotion</TableCell>
              <TableCell align="right">Category</TableCell>
              <TableCell align="right">Platform</TableCell>
              <TableCell align="right" className={classes.create}>
                <Button
                  variant="contained"
                  className={classes.successButton}
                  title="create"
                >
                  <AddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{formatCurrency(row.price)} kr.</TableCell>
                <TableCell align="right">Open Detail</TableCell>
                <TableCell align="right">{row.store_category.name}</TableCell>
                <TableCell align="right">{getSaleAtPlatform(row.sale_at)}</TableCell>
                <TableCell align="right" className={classes.operatorWrapper}>
                  <span
                    className={classnames(classes.operator, classes.editIcon)}
                    title="Edit"
                  >
                    <EditIcon />
                  </span>
                  <span
                    className={classnames(classes.operator, classes.deleteIcon)}
                    title="delete"
                  >
                    <DeleteIcon />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

ProductManagement.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  product: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      sale_at: PropTypes.string,
    })),
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchProduct: PropTypes.func.isRequired,
};

function mapStateToProps({ product, store }) {
  return {
    product,
    store,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProduct: bindActionCreators(fetchProduct, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductManagement));
