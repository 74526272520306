const SHOW_LOADING = Symbol('SHOW_LOADING');
const HIDE_LOADING = Symbol('HIDE_LOADING');

export const actionTypes = {
  SHOW_LOADING,
  HIDE_LOADING,
};

function getInitalStaff() {
  return false;
}

export const initialState = getInitalStaff();
export const showLoading = () => ({ type: SHOW_LOADING, payload: true });
export const hideLoading = () => ({ type: HIDE_LOADING, payload: false });

export const changeLoadingEpic = (action$, store$) => action$
  .ofType(SHOW_LOADING, HIDE_LOADING)
  .filter((action) => {
    const loading = store$.value.ui.loading;
    return loading !== action.payload;
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return true;
    case HIDE_LOADING:
      return false;
    default:
      return state;
  }
};
