import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import styles from './styles';

const Footer = props => (
  <footer className={props.classes.footer}>
    <Typography >Copyright © 2017-2019 E-Screen  All rights reserved.</Typography>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.shape().isRequired,
};


export default withStyles(styles)(Footer);
