import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getSaleAtPlatform } from 'utils/dataTransfer';

function PlatformField({ meta, input, label }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      select
      label={label}
      fullWidth
      margin="normal"
      {...input}
    >
      <MenuItem value={''}>
        Platform
      </MenuItem>
      <MenuItem value={'offline'}>
        {getSaleAtPlatform('offline')}
      </MenuItem>
      <MenuItem value={'online'}>
        {getSaleAtPlatform('online')}
      </MenuItem>
      <MenuItem value={'all'}>
        {getSaleAtPlatform('all')}
      </MenuItem>
      <MenuItem value={'all,offline'}>
        {`${getSaleAtPlatform('all')} & ${getSaleAtPlatform('offline')}`}
      </MenuItem>
      <MenuItem value={'all,online'}>
        {`${getSaleAtPlatform('all')} & ${getSaleAtPlatform('online')}`}
      </MenuItem>
    </TextField>
  );
}

PlatformField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string,
};

PlatformField.defaultProps = {
  label: 'Platform',
};

export default PlatformField;
