const SET_STORE = Symbol('SET_STORE');
const SAVE_STORE = Symbol('SAVE_STORE');
const REMOVE_STORE = Symbol('REMOVE_STORE');
export const actionTypes = {
  SET_STORE,
  REMOVE_STORE,
  SAVE_STORE,
};

function createInitialState() {
  const staff = localStorage.getItem('staff');
  if (staff != null) {
    return JSON.parse(staff).store_info;
  }
  return {};
}

export const initialState = createInitialState();

export const setStore = store => ({
  type: SET_STORE,
  payload: store,
});

export const saveStore = () => ({
  type: SAVE_STORE,
});

export const removeStore = () => ({
  type: REMOVE_STORE,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE:
      return { ...action.payload };
    case REMOVE_STORE:
      return {};
    default:
      return state;
  }
};
