import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

class NetOrderService {
  static fetch(originParams) {
    const params = {
      ...originParams,
    };
    return request.get(`${apiUrlV1}/net_order/`, { params });
  }
}

export default NetOrderService;
