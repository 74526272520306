const APIV1 = 'api/v1';

function getConfig(env) {
  // 开发环境 API 地址
  if (env === 'development') {
    return {
      // apiUrlV1: `http://127.0.0.1:8000/${APIV1}`,
      apiUrlV1: `https://devapi.escreen-host.com/${APIV1}`,
    };
  }

  // 生产环境 API 地址
  if (env === 'production') {
    return {
      apiUrlV1: `https://api.escreen-host.com/${APIV1}`,
    };
  }

  return {
    apiUrlV1: `/${APIV1}`,
  };
}

export default getConfig(process.env.NODE_ENV);
