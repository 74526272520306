import { withStyles } from '@material-ui/core/styles';
import { renderRoutes } from 'react-router-config';

import styles from './styles';

const Shop = ({ route }) => renderRoutes(route.routes);

export default withStyles(styles)(Shop);
export { default as ShopBranch } from './Branch';
export { default as ShopSubtopic } from './Subtopic';
export { default as ShopAD } from './AD';
export { default as ShopHardware } from './Hardware';
export { default as ShopTable } from './Table';
export { default as ShopWebOrder } from './WebOrder';
