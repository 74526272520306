import { colors } from 'themes/variables';
import { linkMixin } from 'themes/mixins';

export default theme => ({
  toolbar: theme.mixins.toolbar,
  wrapper: {
    '& .active span': {
      color: colors.primary,
    },
  },
  inset: {
    paddingLeft: `${theme.spacing.unit * 3}px !important`,
  },
  link: {
    ...linkMixin(),
  },
});
