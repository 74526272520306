import moment from 'moment';
import { isEmpty, get, isNumber } from 'lodash';
import { findTargetPromotion } from 'utils/cartItemTools';

export function formatCurrency(value) {
  try {
    return value.toFixed(2);
  } catch (e) {
    return '';
  }
}

export function targetDiscountValue(orderSkuPrmotions, created) {
  const params = orderSkuPrmotions.map(item => ({
    ...item,
    joined_merchandises: [-1],
  }));
  const target = findTargetPromotion(-1, params, moment(created).toDate());
  if (isEmpty(target)) {
    return 1;
  }
  return target.detail.value;
}

export function getUnitNetOrderSkuItemPrice(orderSkuItem, created) {
  if (isNumber(orderSkuItem.custom_unit_price)) {
    return orderSkuItem.custom_unit_price;
  }
  const extraAmount = orderSkuItem.orderextra_set.reduce(
    (x, y) => x + (y.price * y.count),
    0,
  );
  const discountValue = targetDiscountValue(
    get(orderSkuItem, 'ordermerchandisepromotion_set', []),
    created,
  );
  return (orderSkuItem.price * discountValue) + extraAmount;
}

export function getNetOrderSkuItemPrice(orderSkuItem, created) {
  return getUnitNetOrderSkuItemPrice(orderSkuItem, created) * get(orderSkuItem, 'count', 0);
}

export function getUnitLocalOrderSkuItemPrice(orderSkuItem) {
  if (isNumber(orderSkuItem.custom_unit_price)) {
    return orderSkuItem.custom_unit_price;
  }
  const extraAmount = orderSkuItem.orderextra_set.reduce(
    (x, y) => x + (y.price * y.count),
    0,
  );
  return orderSkuItem.price + extraAmount;
}

export function getLocalOrderSkuItemPrice(orderSkuItem, created) {
  return getUnitLocalOrderSkuItemPrice(orderSkuItem, created) * get(orderSkuItem, 'count', 0);
}

export function getUnitCarryOutOrderSkuItemPrice(orderSkuItem) {
  const extraAmount = orderSkuItem.orderextra_set.reduce(
    (x, y) => x + (y.price * y.count),
    0,
  );
  return orderSkuItem.price + extraAmount;
}

export function getCarryOutOrderSkuItemPrice(orderSkuItem, created) {
  return getUnitCarryOutOrderSkuItemPrice(orderSkuItem, created) * get(orderSkuItem, 'count', 0);
}

export function getNetOrderAmount(order) {
  if (isEmpty(order.ordersku_set)) {
    return 0;
  }
  return order.ordersku_set.reduce(
    (x, y) => x + getNetOrderSkuItemPrice(y, order.created),
    0,
  );
}

export function getLocalOrderAmount(order) {
  if (isEmpty(order.ordersku_set) && isEmpty(order.orderbuffet_set)) {
    return 0;
  }
  const skuAmount = order.ordersku_set.reduce(
    (x, y) => x + getLocalOrderSkuItemPrice(y, order.created),
    0,
  );
  const buffetAmount = order.orderbuffet_set.reduce(
    (x, y) => x + (y.discount * y.count),
    0,
  );
  return skuAmount + buffetAmount;
}

export function getCarryOutOrderAmount(order) {
  if (isEmpty(order.ordersku_set)) {
    return 0;
  }
  return order.ordersku_set.reduce(
    (x, y) => x + getCarryOutOrderSkuItemPrice(y, order.created),
    0,
  );
}
