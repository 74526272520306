import { withStyles } from '@material-ui/core/styles';
import { renderRoutes } from 'react-router-config';

import styles from './styles';

const Product = ({ route }) => renderRoutes(route.routes);


export default withStyles(styles)(Product);
export { default as ProductManagement } from './Management';
export { default as ProductCategory } from './Category';
export { default as ProductAttribute } from './Attribute';
export { default as ProductAttachAttribute } from './AttachAttribute';
export { default as ProductExtra } from './Extra';
