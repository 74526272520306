
import { colors } from 'themes/variables';

export default theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    textAlign: 'center',
    backgroundColor: colors.undertone,
  },
});
