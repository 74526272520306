import React from 'react';
import { toNumber, isNaN } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextInputField from 'components/presentations/fields/TextInputField';
import WeeklySchedule from 'components/presentations/formSections/WeeklyScheduleSection';
import MerchandiseSelector from 'components/containers/selectors/MerchandiseSelector';
import Typography from '@material-ui/core/Typography';

import { Field, reduxForm, propTypes, Form, FormSection } from 'redux-form';

import styles from './styles';

function NameField(props) {
  return <TextInputField label="Name" {...props} />;
}

function ValueField(props) {
  return <TextInputField label="Rate" type="number" {...props} />;
}

function formatValue(rate) {
  const value = toNumber(rate);
  if (isNaN(value)) {
    return 0;
  }
  return value;
}

class MerchandisePromotionEditor extends React.PureComponent {
  render() {
    const { handleSubmit, classes, product } = this.props;

    return (
      <Form noValidate autoComplete="off" onSubmit={handleSubmit} >
        <Typography variant="h6">
          Base Information
        </Typography>
        <Field
          name="name"
          component={NameField}
        />
        <Field
          name="value"
          component={ValueField}
          format={formatValue}
          parse={toNumber}
        />
        <br />
        <br />
        <Typography variant="h6">
          Joined Merchandise
        </Typography>
        <MerchandiseSelector
          product={product}
          onChange={() => null}
        />
        <br />
        <br />
        <Typography variant="h6">
          Activity At
        </Typography>
        <FormSection name="weekly_schedule">
          <WeeklySchedule
            className={classes.weekly}
          />
        </FormSection>
      </Form>
    );
  }
}

MerchandisePromotionEditor.propTypes = {
  ...propTypes,
  product: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const ComponentWidthStyle = withStyles(styles)(MerchandisePromotionEditor);

export default reduxForm({
  form: 'merchandisePromotionEditor',
})(connect(state => ({
  product: state.product,
}))(ComponentWidthStyle));
