import React from 'react';
import { isEmpty, get, includes } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import className from 'classnames';
import { Field, propTypes, Form } from 'redux-form';

import SaleatField from 'components/presentations/fields/SaleatField';
import TextInputField from 'components/presentations/fields/TextInputField';
import styles from './styles';

function includeMerchandiseName(item, names) {
  for (let i = 0; i < names.length; i += 1) {
    if (includes(item.name, names[i])) {
      return true;
    }
  }
  return false;
}

export function filterMerchandise(merchandises, filterObj) {
  return merchandises.filter((item) => {
    if (!isEmpty(get(filterObj, 'id', ''))) {
      const ids = filterObj.id.split(',');
      if (!includes(ids, `${item.id}`)) {
        return false;
      }
    }
    if (!isEmpty(get(filterObj, 'name', ''))) {
      const names = filterObj.name.split(',');
      if (!includeMerchandiseName(item, names)) {
        return false;
      }
    }
    if (!isEmpty(get(filterObj, 'category', ''))) {
      const categories = filterObj.category.split(',');
      if (!includes(categories, item.store_category.name)) {
        return false;
      }
    }
    if (!isEmpty(get(filterObj, 'platform', ''))) {
      const platforms = filterObj.platform.split(',');
      if (!includes(platforms, item.sale_at)) {
        return false;
      }
    }
    return true;
  });
}

function IDField(props) {
  return <TextInputField label="ID" {...props} />;
}

function NameField(props) {
  return <TextInputField label="Name" {...props} />;
}

function CategoryField(props) {
  return <TextInputField label="Category Name" {...props} />;
}

class MerchandiseFilter extends React.PureComponent {
  render() {
    const { classes, handleSubmit, submitting } = this.props;
    return (
      <Form noValidate onSubmit={handleSubmit} autoComplete="off" className={classes.wrapper}>
        <div className={className(classes.inputWrapper, classes.item)}>
          <Field
            name="id"
            component={IDField}
          />
        </div>
        <div className={className(classes.inputWrapper, classes.item)}>
          <Field
            name="name"
            component={NameField}
          />
        </div>
        <div className={className(classes.inputWrapper, classes.item)}>
          <Field
            name="category"
            component={CategoryField}
          />
        </div>
        <div className={className(classes.inputWrapper, classes.item)}>
          <Field
            name="platform"
            component={SaleatField}
          />
        </div>
        <div className={className(classes.buttonWrapper, classes.item)}>
          {
            submitting ?
              <Button
                variant="contained"
                className={classes.button}
                fullWidth
                disabled
              >
                <CircularProgress size={20} />
              </Button>
              :
              <Button
                fullWidth
                variant="contained"
                type="submit"
                className={classes.button}
                title="search"
              ><SearchIcon /></Button>
          }
        </div>
      </Form>
    );
  }
}

MerchandiseFilter.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(MerchandiseFilter);
