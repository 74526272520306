import React from 'react';
import PropTypes from 'prop-types';
import { isArray, isString, isEmpty } from 'lodash';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import className from 'classnames';
import { Field, propTypes, Form } from 'redux-form';

import TextInputField from 'components/presentations/fields/TextInputField';
import MultipleSelectorField from 'components/presentations/fields/MultipleSelectorField';
import DateTimeField from 'components/presentations/fields/DateTimeField';
import { paymentStatusMap } from 'utils/dataTransfer';
import styles from './styles';

function CodeField(props) {
  return <TextInputField label="Order Code" {...props} />;
}

function PaymentStatusField(props) {
  return (<MultipleSelectorField
    {...props}
    items={paymentStatusMap}
    label="Payment Status"
  />);
}

const STATUS_MAP = {
  holding: 'Holding',
  waiting: 'Waiting',
  accepted: 'Accepted',
  delivering: 'Delivering',
  succeed: 'Succeed',
  closed: 'Closed',
};
function OrderStatusField(props) {
  return (<MultipleSelectorField
    {...props}
    items={STATUS_MAP}
    label="Status"
  />);
}

const PAYMENT_WAY_MAP = {
  cash: 'Cash',
  card: 'Card',
  mobilepay: 'Mobilepay',
  member: 'Member',
  order: 'Other',
};
function PaymentWayField(props) {
  return (<MultipleSelectorField
    {...props}
    items={PAYMENT_WAY_MAP}
    label="Payment Way"
  />);
}

function CreatedStartField(props) {
  return <DateTimeField {...props} label="Created At Start" />;
}

function CreatedEndField(props) {
  return <DateTimeField {...props} label="Created At End" />;
}

function formatMultiple(value) {
  if (isString(value)) {
    return value.split(',');
  }
  return '';
}
function parseMultiple(value) {
  if (isArray(value)) {
    return value.join(',');
  }
  return [];
}

function formatMoment(value) {
  if (isEmpty(value)) {
    return null;
  }
  return value;
}

function parseMoment(value) {
  if (moment.isMoment(value)) {
    return value.toISOString();
  }
  return '';
}

class OrderFilter extends React.PureComponent {
  render() {
    const { classes, handleSubmit, submitting, paymentWay, paymentStatus } = this.props;
    return (
      <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <div className={classes.wrapper}>
          <div className={className(classes.inputWrapper, classes.item)}>
            <Field
              name="order_code"
              component={CodeField}
            />
          </div>
        </div>
        <div className={classes.wrapper}>
          <div className={className(classes.inputWrapper, classes.item)}>
            <Field
              name="created_start"
              component={CreatedStartField}
              parse={parseMoment}
              format={formatMoment}
            />
          </div>
          <div className={className(classes.inputWrapper, classes.item)}>
            <Field
              name="created_end"
              component={CreatedEndField}
              parse={parseMoment}
              format={formatMoment}
            />
          </div>
        </div>
        <div className={classes.wrapper}>
          <div className={className(classes.inputWrapper, classes.item)}>
            <Field
              name="status"
              component={OrderStatusField}
              format={formatMultiple}
              parse={parseMultiple}
            />
          </div>
          {
            paymentStatus &&
              <div className={className(classes.inputWrapper, classes.item)}>
                <Field
                  name="pay_status"
                  component={PaymentStatusField}
                  format={formatMultiple}
                  parse={parseMultiple}
                />
              </div>
          }
          {
            paymentWay &&
              <div className={className(classes.inputWrapper, classes.item)}>
                <Field
                  name="payment_type"
                  component={PaymentWayField}
                  format={formatMultiple}
                  parse={parseMultiple}
                />
              </div>
          }
          <div className={className(classes.inputWrapper, classes.item)}>
            {
              submitting ?
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled
                >
                  <CircularProgress size={20} />
                </Button>
                :
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                  title="search"
                >
                  <SearchIcon />
                </Button>
            }
          </div>
        </div>
      </Form>
    );
  }
}

OrderFilter.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
  paymentWay: PropTypes.bool,
  paymentStatus: PropTypes.bool,
};

OrderFilter.defaultProps = {
  paymentWay: false,
  paymentStatus: false,
};

export default withStyles(styles)(OrderFilter);
