import { actionTypes as staffActionTypes } from 'status/partial/staff';

const saveAuthToLocal = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case staffActionTypes.SAVE_STAFF:
      localStorage.setItem('staff', JSON.stringify(state.staff));
      break;
    case staffActionTypes.RESET_STAFF:
      localStorage.removeItem('staff');
      break;
    default:
      break;
  }
  return result;
};

export default saveAuthToLocal;
