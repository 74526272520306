import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import { fetchCategoryEpic } from './category';
import { fetchProductEpic } from './product';
import { fetchBuffetEpic } from './buffet';
import { fetchMerchandisePromotionEpic } from './merchandisePromotion';
import { fetchExtraEpic } from './extra';
import { fetchNetOrderEpic } from './netOrder';
import { fetchLocalOrderEpic } from './localOrder';
import { fetchCarryOutOrderEpic } from './carryOutOrder';
import { fetchTableEpic } from './table';

import { changeLoadingEpic } from './ui/loading';

const rootEpic = combineEpics(
  fetchCategoryEpic,
  fetchProductEpic,
  fetchBuffetEpic,
  fetchMerchandisePromotionEpic,
  fetchExtraEpic,
  fetchNetOrderEpic,
  fetchLocalOrderEpic,
  fetchCarryOutOrderEpic,
  fetchTableEpic,
  changeLoadingEpic,
);

export default rootEpic;
