import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Exception500 = () => (
  <div>Exception 500</div>
);

export default withStyles(styles)(Exception500);
