import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Customer = () => (
  <div>Customers</div>
);

export default withStyles(styles)(Customer);

