import React, { Fragment } from 'react';
import { get, keyBy, isEmpty, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fetchLocalOrder } from 'status/partial/localOrder';
import { fetchTable } from 'status/partial/table';
import { paginationPropTypes } from 'status/schemas/pagination';
import { formatDatetime } from 'utils/datetimeTools';
import { formatCurrency, getLocalOrderAmount } from 'utils/priceTools';
import { LocalOrderFilter } from 'components/containers/forms/OrderFilters';
import InfoModal from './InfoModal';
import styles from './styles';

class LocalOrder extends React.PureComponent {
  state = {
    currentRow: {},
    openDetail: false,
  }

  componentDidMount() {
    const { store, localOrder } = this.props;
    this.fetchData(get(localOrder, 'current_page', 1));
    this.props.fetchTable({
      store: store.id,
    });
  }

  handleChangePage = (e, targetIndex) => {
    const { params } = this.props;
    const target = targetIndex + 1;
    this.fetchData(
      target,
      {
        ...params,
        forceRefresh: true,
      },
    );
  }

  handleRowClick = (row) => {
    this.setState({
      currentRow: row,
      openDetail: true,
    });
  }

  handleDetailClose = () => {
    this.setState({
      openDetail: false,
    });
  }

  handleFilter = (values) => {
    const { localOrder } = this.props;
    this.fetchData(
      get(localOrder, 'current_page', 1),
      {
        forceRefresh: true,
        ...values,
      },
    );
  }

  fetchData = (page = 1, params = {}) => {
    const { store } = this.props;
    this.props.fetchLocalOrder({
      store: store.id,
      ...params,
      page,
    });
  }

  render() {
    const { openDetail, currentRow } = this.state;
    const { classes, localOrder, table, params } = this.props;

    const results = get(localOrder, 'results', []);
    return (
      <Fragment>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" id="tableTitle">
              Local Order
            </Typography>
          </Toolbar>
          <div className={classes.toolbar}>
            <LocalOrderFilter
              onSubmit={this.handleFilter}
              initialValues={params}
              destroyOnUnmount={false}
              paymentWay
            />
          </div>
          <br />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="right">Order Number</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Staff</TableCell>
                <TableCell align="right">Table</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(row => (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => this.handleRowClick(row)}
                >
                  <TableCell align="right">{row.order_code}</TableCell>
                  <TableCell align="right">{formatCurrency(getLocalOrderAmount(row))}</TableCell>
                  <TableCell align="right">
                    {
                      !isEmpty(row.staff_name) ? row.staff_name : 'Unknow Staff'
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      get(
                        table,
                        row.table,
                        {
                          name: 'Unknow Table',
                        },
                      ).name
                    }</TableCell>
                  <TableCell align="right">{formatDatetime(row.created)}</TableCell>
                  <TableCell align="right">{upperFirst(row.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={localOrder.count}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={localOrder.current_page - 1}
            onChangePage={this.handleChangePage}
          />
        </Paper>
        <InfoModal
          open={openDetail}
          row={currentRow}
          handleClose={this.handleDetailClose}
        />
      </Fragment>
    );
  }
}

LocalOrder.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  localOrder: paginationPropTypes.isRequired,
  table: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchLocalOrder: PropTypes.func.isRequired,
  fetchTable: PropTypes.func.isRequired,
  params: PropTypes.shape().isRequired,
};

function mapStateToProps({ localOrder, store, table, form }) {
  const tableMap = keyBy(get(table, 'results', []), 'id');
  return {
    localOrder,
    store,
    table: tableMap,
    params: get(form, 'localOrderFilter.values', {}),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLocalOrder: bindActionCreators(fetchLocalOrder, dispatch),
    fetchTable: bindActionCreators(fetchTable, dispatch),
  };
}

const LocalOrderWidthStyle = withStyles(styles)(LocalOrder);
export default connect(mapStateToProps, mapDispatchToProps)(LocalOrderWidthStyle);

