import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import CategoryService from 'apis/services/category';

const FETCH_CATEGORY = Symbol('FETCH_CATEGORY');
const FETCH_CATEGORY_SUCCESS = Symbol('FETCH_CATEGORY_SUCCESS');

export const initialState = {
  results: [],
};

export const fetchCategory = params => ({ type: FETCH_CATEGORY, payload: params });
export const fetchCategorySuccess = response => ({
  type: FETCH_CATEGORY_SUCCESS,
  payload: response,
});

export const fetchCategoryEpic = (action$, store$) =>
  action$.ofType(FETCH_CATEGORY).mergeMap((action) => {
    const category = store$.value.category;
    if (isEmpty(category.results)) {
      return Observable.fromPromise(CategoryService.fetch(action.payload))
        .map(response => fetchCategorySuccess(response));
    }
    return Observable.of(fetchCategorySuccess({ data: category }));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
