import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Filter from './Filter';

export const MerchandiseFilter = reduxForm({
  form: 'merchandiseFilter',
})(connect()(Filter));

export const PromotionMerchandiseFilter = reduxForm({
  form: 'promotionMerchandiseFilter',
})(connect()(Filter));

export { filterMerchandise } from './Filter';
