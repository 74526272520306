import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Field, FormSection } from 'redux-form';
import TimeField from 'components/presentations/fields/TimeField';
import styles from './styles';

function formatMoment(value) {
  if (isEmpty(value)) {
    return null;
  }
  return `2000-01-01 ${value}`;
}

function parseMoment(value) {
  if (moment.isMoment(value)) {
    return value.format('HH:mm:ss');
  }
  return '';
}

const WEEKLY_LIST = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const sections = WEEKLY_LIST.map(day => ({
  name: day,
  Start: props => <TimeField label={`${day} Start Time`} {...props} />,
  End: props => <TimeField label={`${day} End Time`} {...props} />,
}));

class WeeklyScheduleSection extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {
          sections.map(section => (
            <FormSection
              name={section.name}
              className={classes.section}
              key={section.name}
            >
              <Field
                name="starttime"
                component={section.Start}
                format={formatMoment}
                parse={parseMoment}
              />
              <span className={classes.gap} />
              <Field
                name="endtime"
                component={section.End}
                format={formatMoment}
                parse={parseMoment}
              />
            </FormSection>
          ))
        }
      </React.Fragment>
    );
  }
}

WeeklyScheduleSection.propTypes = {
  classes: PropTypes.shape().isRequired,
};

WeeklyScheduleSection.defaultProps = {

};

export default withStyles(styles)(WeeklyScheduleSection);
