import { withStyles } from '@material-ui/core/styles';
import { renderRoutes } from 'react-router-config';

import styles from './styles';

const Promotion = ({ route }) => renderRoutes(route.routes);


export default withStyles(styles)(Promotion);
export { default as PromotionMerchandise } from './Merchandise';
