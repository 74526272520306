import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import { renderRoutes } from 'react-router-config';
// import TopBar from 'components/presentations/TopBar';
import SiderBar from 'components/presentations/SiderBar';
import Footer from 'components/presentations/Footer';

import styles from './styles';

class BasicLayout extends React.Component {
  state = {
    openSnackbar: true,
  };

  handleClose = () => {
    this.setState({ openSnackbar: false });
  }

  render() {
    const { classes, route, store, loading } = this.props;
    const { openSnackbar } = this.state;
    return (
      <div className={classes.root}>
        {
          loading &&
            <LinearProgress
              className={classes.loadingBar}
              color="primary"
            />
        }

        {/*
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <TopBar
                store={store}
              />
            </Toolbar>
          </AppBar>
        */}
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <div className={classes.toolbarIcon}>
            <img src={store.logo} alt={store.name} />
          </div>
          <SiderBar />
        </Drawer>
        <main className={classNames(classes.content, classes.appBarShift)}>
          <section>
            {renderRoutes(route.routes)}
          </section>
          <Footer />
        </main>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          onClose={this.handleClose}
          autoHideDuration={3000}
          message={
            <span>
              EScreen administration site alpha version now available.{' '}
              If you find any bug,{' '}
              please {"don't"} hesitate to contact us.
            </span>
          }
        />
      </div>
    );
  }
}

BasicLayout.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  route: PropTypes.instanceOf(Object).isRequired,
  store: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

const ComponentWithStyle = withStyles(styles)(BasicLayout);
export default connect(
  state => ({
    store: state.store,
    loading: state.ui.loading,
  }),
)(ComponentWithStyle);
