import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

function TextInputField({ input, meta, label, ...extra }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      label={label}
      margin="normal"
      fullWidth
      {...input}
      {...extra}
    />
  );
}

TextInputField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string,
};

TextInputField.defaultProps = {
  label: '',
};
export default TextInputField;
