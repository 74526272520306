import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const ShopSubtopic = () => (
  <div>Shop Subtopic</div>
);

export default withStyles(styles)(ShopSubtopic);

