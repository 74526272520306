import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export default function resetPassowrd(params) {
  return request.post(`${apiUrlV1}/staff/password/reset/`, params);
}

export function modifyPassowrd(params) {
  return request.post(`${apiUrlV1}/password/modify/`, params);
}
