import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import routeNames from 'routes/routeNames';

import styles from './styles';

class SiderBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openMerchansie: true,
      openPromotion: true,
      openOrder: true,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Divider />
        <List>
          <NavLink
            to={{ pathname: routeNames.dashboard }}
            className={classes.link}
          >
            <ListItem button>
              <ListItemText>Dashboard</ListItemText>
            </ListItem>
          </NavLink>

          <ListItem
            button
            onClick={() => this.setState({
              openOrder: !this.state.openOrder,
            })}
          >
            <ListItemText primary="Order" />
            {this.state.openOrder ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openOrder} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                to={{ pathname: routeNames.orderWeb }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Web Order" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
              <NavLink
                to={{ pathname: routeNames.orderLocal }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Local Order" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
              <NavLink
                to={{ pathname: routeNames.orderCarryOut }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Carry Out Order" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => this.setState({
              openMerchansie: !this.state.openMerchansie,
            })}
          >
            <ListItemText primary="Merchandise" />
            {this.state.openMerchansie ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openMerchansie} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                to={{ pathname: routeNames.productManagement }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Management" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
              <NavLink
                to={{ pathname: routeNames.productAttribute }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Attribute" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
              <NavLink
                to={{ pathname: routeNames.productAttachAttribute }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Attach Attribute" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
              <NavLink
                to={{ pathname: routeNames.productCategory }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Category" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
              <NavLink
                to={{ pathname: routeNames.productExtra }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Extra" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => this.setState({
              openPromotion: !this.state.openPromotion,
            })}
          >
            <ListItemText primary="Promotion" />
            {this.state.openPromotion ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openPromotion} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                to={{ pathname: routeNames.promotionMerchandise }}
                className={classes.link}
              >
                <ListItem button>
                  <ListItemText inset primary="Merchandise" classes={{ inset: classes.inset }} />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>
          <NavLink
            to={{ pathname: routeNames.buffet }}
            className={classes.link}
          >
            <ListItem button>
              <ListItemText primary={'Buffet'} />
            </ListItem>
          </NavLink>
        </List>
      </div>
    );
  }
}

SiderBar.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SiderBar);
