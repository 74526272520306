import React from 'react';
import { upperFirst, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { formatCurrency } from 'utils/priceTools';
import { formatDatetime } from 'utils/datetimeTools';
import { getDeliveryType } from 'utils/dataTransfer';

import styles from './styles';

class OrderBaseTable extends React.PureComponent {
  render() {
    const { order, amount, deliveryAmount } = this.props;
    const row = order;
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Amount:</TableCell>
            <TableCell>
              { `${formatCurrency(amount)} ` }
              {
                !isEmpty(deliveryAmount) &&
                  `(Contains Delivery Amount: ${formatCurrency(deliveryAmount)})`
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Created:</TableCell>
            <TableCell>{ formatDatetime(row.created) }</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Modified:</TableCell>
            <TableCell>{ formatDatetime(row.modified) }</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Payment Way:</TableCell>
            <TableCell>{ upperFirst(row.payment_type) }</TableCell>
          </TableRow>
          {
            !isEmpty(row.pay_status) &&
              <TableRow>
                <TableCell>Payment Status:</TableCell>
                <TableCell>{ upperFirst(row.pay_status) }</TableCell>
              </TableRow>
          }
          {
            !isEmpty(row.order_type) &&
              <TableRow>
                <TableCell>Type:</TableCell>
                <TableCell>{ getDeliveryType(row.order_type) }</TableCell>
              </TableRow>
          }
          <TableRow>
            <TableCell>Status:</TableCell>
            <TableCell>{ upperFirst(row.status) }</TableCell>
          </TableRow>
          {
            !isEmpty(row.description) &&
              <TableRow>
                <TableCell>Note:</TableCell>
                <TableCell>{ row.description }</TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
    );
  }
}

OrderBaseTable.propTypes = {
  // classes: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.shape({}),
  amount: PropTypes.number,
  deliveryAmount: PropTypes.number,
};

OrderBaseTable.defaultProps = {
  order: {},
  amount: 0,
  deliveryAmount: 0,
};

export default withStyles(styles)(OrderBaseTable);
