import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const ShopTable = () => (
  <div>Shop Table</div>
);

export default withStyles(styles)(ShopTable);
