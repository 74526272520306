import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { TimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

function TimeField({ input, label, ...extra }) {
  const value = isEmpty(input.value) ? null : input.value;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        {...input}
        value={value}
        seconds
        fullWidth
        clearable
        ampm={false}
        margin="normal"
        format="HH:mm:ss"
        label={label}
        {...extra}
      />
    </MuiPickersUtilsProvider>
  );
}

TimeField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
};

TimeField.defaultProps = {
  label: 'Pick a time',
};

export default TimeField;
