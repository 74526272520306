import React, { Fragment } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { fetchExtra } from 'status/partial/extra';

import styles from './styles';

class ProductExtra extends React.PureComponent {
  componentDidMount() {
    const { store } = this.props;
    this.props.fetchExtra({
      store: store.id,
    });
  }

  render() {
    const { classes, extra } = this.props;

    const extraResults = get(extra, 'results', []);
    const results = extraResults;
    return (
      <Fragment>
        <header className={classes.header} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Ranking</TableCell>
              <TableCell align="right" className={classes.create}>
                <Button
                  variant="contained"
                  className={classes.successButton}
                  title="create"
                >
                  <AddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.ranking_index}</TableCell>
                <TableCell align="right" className={classes.operatorWrapper}>
                  <span
                    className={classnames(classes.operator, classes.editIcon)}
                    title="Edit"
                  >
                    <EditIcon />
                  </span>
                  <span
                    className={classnames(classes.operator, classes.deleteIcon)}
                    title="delete"
                  >
                    <DeleteIcon />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

ProductExtra.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  extra: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchExtra: PropTypes.func.isRequired,
};

function mapStateToProps({ extra, store }) {
  return {
    extra,
    store,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchExtra: bindActionCreators(fetchExtra, dispatch),
  };
}

const ProductExtraWidthStyle = withStyles(styles)(ProductExtra);
export default connect(mapStateToProps, mapDispatchToProps)(ProductExtraWidthStyle);
