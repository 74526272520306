import { message } from 'antd';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import store from 'status/store';
import { showLoading, hideLoading } from 'status/partial/ui/loading';

// response
// success
export const pass = response => response;

export function setStateLoadingHide(response) {
  store.dispatch(hideLoading());
  return response;
}

// fail
export function errorInterceptor(error) {
  return Promise.reject(error);
}

export function unauthorizedInterceptor(error) {
  const state = store.getState();
  const location = get(state.router, 'location', {});
  const pathname = get(location, 'pathname', '/');
  if (error.response.status === 401 && pathname !== '/login') {
    store.dispatch(push({
      pathname: '/login',
      state: {
        from: pathname,
      },
    }));
    message.info('Log venligst ind før');
  }
  return Promise.reject(error);
}


// request
export function widthToken(config = {}) {
  const state = store.getState();
  const token = get(state.auth, 'token', '');
  const oldHeaders = get(config, 'headers', {});
  return {
    ...config,
    ...{
      headers: {
        ...oldHeaders,
        authorization: `JWT ${token}`,
      },
    },
  };
}

export function setStateLoadingShow(config) {
  store.dispatch(showLoading());
  return config;
}
