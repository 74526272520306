import React from 'react';
import { isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { fetchMerchandisePromotion } from 'status/partial/merchandisePromotion';
import { fetchProduct } from 'status/partial/product';
import EditorModal from './EditorModal';
import styles from './styles';

class PromotionMerchandise extends React.PureComponent {
  state = {
    openEditor: false,
    currentRow: {},
  }

  componentDidMount() {
    const { merchandisePromotion, store } = this.props;
    this.fetchData(get(merchandisePromotion, 'current_page', 1));
    this.props.fetchProduct({
      store: store.id,
    });
  }

  handleRowClick = (row) => {
    this.setState({
      currentRow: row,
      openEditor: true,
    });
  }

  handleEditorClose = () => {
    this.setState({
      openEditor: false,
    });
  }

  handleEditorSave = (values) => {
    console.log(values);
  }

  handleChangePage = (e, targetIndex) => {
    const target = targetIndex + 1;
    this.fetchData(target);
  }

  fetchData = (page = 1, params = {}) => {
    const { store } = this.props;
    this.props.fetchMerchandisePromotion({
      store: store.id,
      page,
      ...params,
    });
  }

  render() {
    const { openEditor, currentRow } = this.state;
    const { classes, merchandisePromotion } = this.props;

    const fetchMerchandisePromotionResults = isEmpty(merchandisePromotion) ?
      [] : merchandisePromotion.results;
    const results = fetchMerchandisePromotionResults;
    return (
      <React.Fragment>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" id="tableTitle">
              Merchandise Promotion
            </Typography>
          </Toolbar>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Merchandise Count</TableCell>
                <TableCell align="right">Off</TableCell>
                <TableCell align="right" className={classes.create}>
                  <Button
                    variant="contained"
                    className={classes.successButton}
                    title="create"
                  >
                    <AddIcon />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">
                    {row.joined_merchandises.length}
                  </TableCell>
                  <TableCell align="right">{100 - (row.detail.value * 100)}%</TableCell>
                  <TableCell align="right" className={classes.operatorWrapper}>
                    <span
                      className={classnames(classes.operator, classes.editIcon)}
                      title="Edit"
                      onClick={() => this.handleRowClick(row)}
                    >
                      <EditIcon />
                    </span>
                    <span
                      className={classnames(classes.operator, classes.deleteIcon)}
                      title="delete"
                    >
                      <DeleteIcon />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={merchandisePromotion.count}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            page={merchandisePromotion.current_page - 1}
            onChangePage={this.handleChangePage}
          />
        </Paper>
        <EditorModal
          open={openEditor}
          row={currentRow}
          handleClose={this.handleEditorClose}
          handleSave={this.handleEditorSave}
        />
      </React.Fragment>
    );
  }
}

PromotionMerchandise.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  merchandisePromotion: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchMerchandisePromotion: PropTypes.func.isRequired,
  fetchProduct: PropTypes.func.isRequired,
};

function mapStateToProps({ merchandisePromotion, store }) {
  return {
    merchandisePromotion,
    store,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMerchandisePromotion: bindActionCreators(fetchMerchandisePromotion, dispatch),
    fetchProduct: bindActionCreators(fetchProduct, dispatch),
  };
}

const PromotionMerchandiseWidthStyle = withStyles(styles)(PromotionMerchandise);

export default connect(mapStateToProps, mapDispatchToProps)(PromotionMerchandiseWidthStyle);
