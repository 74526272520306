const SET_STAFF = Symbol('SET_STAFF');
const RESET_STAFF = Symbol('RESET_STAFF');
const SAVE_STAFF = Symbol('SAVE_STAFF');

export const actionTypes = {
  SET_STAFF,
  RESET_STAFF,
  SAVE_STAFF,
};

function getInitalStaff() {
  const localStaff = localStorage.getItem('staff');
  if (localStaff != null) {
    return JSON.parse(localStaff);
  }
  return {};
}

export const initialState = getInitalStaff();

export const setStaff = auth => ({ type: SET_STAFF, payload: auth });
export const saveStaff = () => ({ type: SAVE_STAFF });
export const resetStaff = () => ({ type: RESET_STAFF });

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STAFF:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_STAFF:
      return {};
    case SAVE_STAFF:
      return state;
    default:
      return state;
  }
};
