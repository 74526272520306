import React from 'react';
import { get, every, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OrderItemTable from 'components/presentations/OrderItemTable';
import OrderBaseTable from 'components/presentations/OrderBaseTable';
import OrderExtraTable from 'components/presentations/OrderExtraTable';

import { formatCurrency, getLocalOrderAmount } from 'utils/priceTools';

import { infoModalStyles as styles } from './styles';

function isShowExtraTable(row) {
  const extrasetList = get(row, 'ordersku_set', [])
    .map(item => get(item, 'orderextra_set', []));
  return !every(extrasetList, extraset => extraset.length === 0);
}

class InfoModal extends React.PureComponent {
  handleClose = (e, row) => {
    this.props.handleClose(e, row);
  }

  render() {
    const { open, row, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
      >
        <DialogTitle>Order Number: {row.order_code}</DialogTitle>
        <DialogContent className={classes.content}>
          <Paper>
            <Toolbar>
              <Typography variant="h6">
                Base Information
              </Typography>
            </Toolbar>
            <OrderBaseTable
              order={row}
              amount={getLocalOrderAmount(row)}
              deliveryAmount={row.delivery_amount}
            />
          </Paper>
          <br />
          {
            !isEmpty(get(row, 'ordersku_set', [])) &&
              <Paper>
                <Toolbar>
                  <Typography variant="h6">
                    Items
                  </Typography>
                </Toolbar>
                <OrderItemTable
                  skuset={get(row, 'ordersku_set', [])}
                  created={row.created}
                />
                {
                  isShowExtraTable(row) &&
                  <React.Fragment>
                    <br />
                    <Toolbar>
                      <Typography variant="h6">
                        Extras
                      </Typography>
                    </Toolbar>
                    <OrderExtraTable
                      skuset={get(row, 'ordersku_set', [])}
                    />
                  </React.Fragment>
                }
              </Paper>
          }
          {
            !isEmpty(row.orderbuffet_set) &&
              <React.Fragment>
                <br />
                <Paper>
                  <Toolbar>
                    <Typography variant="h6">
                      Buffets
                    </Typography>
                  </Toolbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Count</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        get(row, 'orderbuffet_set', []).map(buffet => (
                          <TableRow key={buffet.id}>
                            <TableCell>{ buffet.name }</TableCell>
                            <TableCell>{ formatCurrency(buffet.discount) }</TableCell>
                            <TableCell>{ buffet.count }</TableCell>
                            <TableCell>
                              { formatCurrency(buffet.discount * buffet.count) }
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                  {
                    get(row, 'orderbuffet_set', []).map(buffet => (
                      <React.Fragment key={buffet.id}>
                        <br />
                        <div className={classes.margin}>
                          <Toolbar>
                            <Typography variant="subtitle1">
                              Buffet Plan Name: { buffet.name }
                            </Typography>
                          </Toolbar>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Count</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                get(buffet, 'orderbuffetsku_set', []).map(sku => (
                                  <TableRow key={sku.id}>
                                    <TableCell>
                                      { sku.name }
                                    </TableCell>
                                    <TableCell>
                                      { sku.count }
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </div>
                      </React.Fragment>
                    ))
                  }
                </Paper>
              </React.Fragment>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Cencel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InfoModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.shape({}).isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

InfoModal.defaultProps = {
  open: false,
  handleClose: () => null,
};

export default withMobileDialog()(withStyles(styles)(InfoModal));
