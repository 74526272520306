function makeRouteMap() {
  return {
    root: '/',
    login: '/login',
    term: '/term',
    setPassword: '/set_password',
    admin: '/admin',
    dashboard: '/admin/dashboard',
    product: '/admin/product',
    productManagement: '/admin/product/management',
    productCategory: '/admin/product/category',
    productAttribute: '/admin/product/attribute',
    productAttachAttribute: '/admin/product/attach_attribute',
    productExtra: '/admin/product/extra',
    promotion: '/admin/promotion',
    promotionMerchandise: '/admin/promotion/merchandise',
    order: '/admin/order',
    orderWeb: '/admin/order/web',
    orderLocal: '/admin/order/local',
    orderCarryOut: '/admin/order/carry_out',
    orderStatistics: '/admin/order/statistics',
    customer: '/admin/customer',
    buffet: '/admin/buffet',
    shop: '/admin/shop',
    shopBranch: '/admin/shop/branch',
    // ... TODO
  };
}

export default makeRouteMap();
