import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { fetchCategory } from 'status/partial/category';
import { fetchProduct } from 'status/partial/product';
import { addMerchandisesAsKey } from 'utils/dataTransfer';

import styles from './styles';

function getCategoryAndMerchandiseMap(merchandises, categories) {
  return addMerchandisesAsKey(merchandises, categories, 'merchandises');
}

class ProductCategory extends React.PureComponent {
  componentDidMount() {
    const { store } = this.props;
    this.props.fetchCategory({
      store: store.id,
    });
    this.props.fetchProduct({
      store: store.id,
    });
  }

  render() {
    const { classes, category, product } = this.props;

    const categoryResults = isEmpty(category) ? [] : category.results;
    const productResults = isEmpty(product) ? [] : product.results;
    const results = getCategoryAndMerchandiseMap(productResults, categoryResults);
    return (
      <Fragment>
        <header className={classes.header} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Merchandise Count</TableCell>
              <TableCell align="right">Ranking</TableCell>
              <TableCell align="right" className={classes.create}>
                <Button
                  variant="contained"
                  className={classes.successButton}
                  title="create"
                >
                  <AddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">
                  {
                    isEmpty(productResults) ?
                      'loading'
                      :
                      row.merchandises.length
                  }
                </TableCell>
                <TableCell align="right">{row.ranking_index}</TableCell>
                <TableCell align="right" className={classes.operatorWrapper}>
                  <span
                    className={classnames(classes.operator, classes.editIcon)}
                    title="Edit"
                  >
                    <EditIcon />
                  </span>
                  <span
                    className={classnames(classes.operator, classes.deleteIcon)}
                    title="delete"
                  >
                    <DeleteIcon />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

ProductCategory.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      ranking_index: PropTypes.number,
      image: PropTypes.string,
      store: PropTypes.number,
    })),
  }).isRequired,
  product: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      sale_at: PropTypes.string,
    })),
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchCategory: PropTypes.func.isRequired,
  fetchProduct: PropTypes.func.isRequired,
};

function mapStateToProps({ category, store, product }) {
  return {
    category,
    store,
    product,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCategory: bindActionCreators(fetchCategory, dispatch),
    fetchProduct: bindActionCreators(fetchProduct, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductCategory));
