import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import ProductService from 'apis/services/product';

export const initialState = {
  count: 0,
  results: [],
};

const FETCH_PRODUCT = Symbol('FETCH_PRODUCT');
const FETCH_PRODUCT_SUCCESS = Symbol('FETCH_PRODUCT_SUCCESS');

export const fetchProduct = params => ({ type: FETCH_PRODUCT, payload: params });
export const fetchProductSuccess = response => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: response,
});

export const fetchProductEpic = (action$, store$) =>
  action$.ofType(FETCH_PRODUCT).filter((action, index) => {
    const product = store$.value.product;
    if (index === 0) {
      return true;
    }
    if (isEmpty(product.results)) {
      return false;
    }
    return true;
  }).mergeMap((action) => {
    const product = store$.value.product;
    if (isEmpty(product.results)) {
      return Observable.fromPromise(ProductService.fetch(action.payload))
        .map(response => fetchProductSuccess(response));
    }
    return Observable.of(fetchProductSuccess({ data: product }));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
