import React from 'react';
import { connect } from 'react-redux';
import Footer from 'components/presentations/Footer';
// import Topbar from 'components/presentations/Topbar';
import SignForm from 'components/containers/forms/Signin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { signin, getStaffInfo } from 'apis/services/signin';
import { omit, isEmpty, get } from 'lodash';
import { message } from 'antd';
import { bindActionCreators } from 'redux';
import { setAuth, saveAuth } from 'status/partial/auth';
import { setStaff, saveStaff } from 'status/partial/staff';
import { setStore, saveStore } from 'status/partial/store';
import { push } from 'connected-react-router';
import routeNames from 'routes/routeNames';
import styles from './styles';

class Signin extends React.PureComponent {
  async submit(values) {
    const { routing } = this.props;
    const to = get(routing.location.state, 'from', null);
    // signin
    const signinData = await signin(omit(values, ['saved'])).then((res) => {
      this.props.setAuth(res.data);
      if (values.saved) {
        this.props.saveAuth(res.data);
      }
      return res.data;
    }).catch(() => {
      message.error('brugernavn eller adgangskode er forkert.');
    });
    // get the staff info
    if (signinData != null) {
      getStaffInfo({
        user: signinData.user.pk,
      }).then((res) => {
        const target = res.data.results[0];
        this.props.setStaff(target);
        this.props.setStore(target.store_info);
        if (values.saved) {
          this.props.saveStaff(target);
          this.props.saveStore(target.store_info);
        }
        if (to != null) {
          this.props.dispatch(push(to));
        } else {
          this.props.dispatch(push(routeNames.dashboard));
        }
      }).catch(() => {
        message.error('brugernavn eller adgangskode er forkert.');
      });
    }
  }

  render() {
    const { classes, routing, auth } = this.props;
    return (
      <main className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.formWrapper}>
            <SignForm
              onSubmit={values => this.submit(values)}
              to={routing.location.search}
              booking={isEmpty(auth)}
            />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

Signin.propTypes = {
  classes: PropTypes.shape().isRequired,
  routing: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  saveAuth: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired,
  setStaff: PropTypes.func.isRequired,
  saveStaff: PropTypes.func.isRequired,
  setStore: PropTypes.func.isRequired,
  saveStore: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    routing: state.router,
    cart: state.cart,
    auth: state.auth,
  }),
  dispatch => ({
    ...bindActionCreators({
      setAuth,
      saveAuth,
      setStaff,
      saveStaff,
      setStore,
      saveStore,
    }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Signin));
