import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import className from 'classnames';
import { Link } from 'react-router-dom';
import { Field, reduxForm, propTypes, Form, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import EmailField from 'components/presentations/fields/EmailField';
import NameField from 'components/presentations/fields/NameField';
import PasswordField from 'components/presentations/fields/PasswordField';
import validator from 'utils/validator';
import resetPassowrd from 'apis/services/resetPassowrd';
import routeNames from 'routes/routeNames';
import { message } from 'antd';
import styles from './styles';

function Saved({ input }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={input.onChange}
          color="primary"
        />
      }
      label="Forbliv logget ind"
    />
  );
}

Saved.propTypes = {
  input: PropTypes.shape().isRequired,
};

class Signin extends React.PureComponent {
  state = {
    open: false,
    sending: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  sendEmail = async (values) => {
    message.loading('', 30);
    this.setState({
      sending: true,
    });
    try {
      await resetPassowrd({
        email: values.email,
        username: values.username,
      });
      message.destroy();
      message.success('Email sendt');
      await this.setState({
        open: false,
        sending: false,
      });
    } catch (error) {
      message.destroy();
      message.error('Indtast venligst korrekt navn og e-mail.');
      await this.setState({
        sending: false,
      });
    }
  }

  render() {
    const { classes, handleSubmit, submitting, values } = this.props;
    return (
      <div>
        <div className={className(classes.padding, classes.middle)}>
          <AccountIcon className={classes.title} />
        </div>
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Field
            name="username"
            component={NameField}
          />
          <Field
            name="password"
            component={PasswordField}
          />
          <div className={classes.bottom}>
            <Field
              name="saved"
              component={Saved}
            />
            <Button color="primary" className={classes.forgot} onClick={this.handleClickOpen}>Glemt din adgangskode?</Button>
          </div>
          <div className={classes.padding}>
            {
              submitting ?
                <Button color="primary" variant="contained" fullWidth disabled>
                  <CircularProgress size={20} />
                </Button>
                :
                <Button color="primary" variant="contained" fullWidth type="submit">Log ind</Button>
            }
          </div>
          <Typography className={className(classes.padding, classes.middle)}>
            Ved at oprette en konto accepterer du
            <Link
              to={{ pathname: routeNames.term }}
              className={classes.light}
            >
              vores vilkår og betingelser
            </Link>
            {' og '}
            <Link
              to={{ pathname: routeNames.term }}
              className={classes.light}
            >
              privatlivspolitik
            </Link>.
          </Typography>
        </Form>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Glemt din adgangskode?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Har du glemt din adgangskode?Indtast dit brugernavn (e-mail)
              , og vi sender dig code på e-mail.
            </DialogContentText>
            <Field
              name="username"
              component={NameField}
            />
            <Field
              name="email"
              component={EmailField}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default">
              Afbestille
            </Button>
            <Button onClick={() => this.sendEmail(values)} color="primary" disabled={this.state.sending}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

Signin.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
};

const selector = formValueSelector('signin');
export default reduxForm({
  form: 'signin',
  initialValues: {
    saved: true,
  },
  validate(values) {
    const result = {};
    if (!validator.isPassword(values.password)) {
      result.password = 'Adgangskode længde større end eller lig med.';
    }
    if (!validator.isUserName(values.username)) {
      result.username = 'Indtast venligst korrekt brugernavn.';
    }
    return result;
  },
})(connect(state => ({
  values: selector(state, 'username', 'email'),
}))(withStyles(styles)(Signin)));
