import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

class ExtraService {
  static fetch(originParams) {
    const params = {
      ...{ page_size: 1000 },
      ...originParams,
    };
    return request.get(`${apiUrlV1}/merchandise_extra/`, { params });
  }
}

export default ExtraService;
