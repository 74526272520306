import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export function signin(params) {
  return request.post(`${apiUrlV1}/login/`, params);
}

export function getStaffInfo(params) {
  return request.get(`${apiUrlV1}/staff/`, { params });
}
