import { createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import { colors, screens } from './variables';

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: amber[400],
      dark: amber[700],
    },
  },
  breakpoints: {
    values: {
      ...screens,
    },
  },
  zIndex: {
    mobileStepper: 100,
    appBar: 110,
    drawer: 120,
    modal: 130,
    snackbar: 140,
    tooltip: 150,
  },
  typography: {
    useNextVariants: true,
  },
});
