import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { fetchBuffet } from 'status/partial/buffet';

import styles from './styles';


class Buffet extends React.PureComponent {
  componentDidMount() {
    const { store } = this.props;
    this.props.fetchBuffet({
      store: store.id,
    });
  }

  handleChangePage = () => {}

  render() {
    const { classes, buffet } = this.props;

    const results = isEmpty(buffet) ? [] : buffet.results;
    return (
      <Paper>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle">
            Buffet
          </Typography>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Name</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Discount</TableCell>
              <TableCell align="right">Merchandise Count</TableCell>
              <TableCell align="right" className={classes.create}>
                <Button
                  variant="contained"
                  className={classes.successButton}
                  title="create"
                >
                  <AddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.discount}</TableCell>
                <TableCell align="right">
                  {row.plandetail_set.length}
                </TableCell>
                <TableCell align="right" className={classes.operatorWrapper}>
                  <span
                    className={classnames(classes.operator, classes.editIcon)}
                    title="Edit"
                  >
                    <EditIcon />
                  </span>
                  <span
                    className={classnames(classes.operator, classes.deleteIcon)}
                    title="delete"
                  >
                    <DeleteIcon />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={buffet.count}
          rowsPerPage={20}
          rowsPerPageOptions={[20]}
          page={buffet.current_page - 1}
          onChangePage={this.handleChangePage}
        />
      </Paper>
    );
  }
}

Buffet.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  buffet: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.shape({
    })),
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  fetchBuffet: PropTypes.func.isRequired,
};

function mapStateToProps({ buffet, store }) {
  return {
    buffet,
    store,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchBuffet: bindActionCreators(fetchBuffet, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Buffet));
