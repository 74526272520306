import { withStyles } from '@material-ui/core/styles';
import { renderRoutes } from 'react-router-config';

import styles from './styles';

const Exception = ({ route }) => renderRoutes(route.routes);


export default withStyles(styles)(Exception);
export { default as Exception400 } from './400';
export { default as Exception404 } from './404';
export { default as Exception500 } from './500';
