import React from 'react';
import { get, every } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import withMobileDialog from '@material-ui/core/withMobileDialog';
// import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OrderItemTable from 'components/presentations/OrderItemTable';
import OrderBaseTable from 'components/presentations/OrderBaseTable';
import OrderExtraTable from 'components/presentations/OrderExtraTable';
import { formatDatetime } from 'utils/datetimeTools';
import { getCarryOutOrderAmount } from 'utils/priceTools';
import { infoModalStyles as styles } from './styles';

function isShowExtraTable(row) {
  const extrasetList = get(row, 'ordersku_set', [])
    .map(item => get(item, 'orderextra_set', []));
  return !every(extrasetList, extraset => extraset.length === 0);
}

class InfoModal extends React.PureComponent {
  handleClose = (e, row) => {
    this.props.handleClose(e, row);
  }

  render() {
    const { open, row, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
      >
        <DialogTitle>Order Number: {row.order_code}</DialogTitle>
        <DialogContent className={classes.content}>
          <Paper>
            <Toolbar>
              <Typography variant="h6">
                Base Information
              </Typography>
            </Toolbar>
            <OrderBaseTable
              order={row}
              amount={getCarryOutOrderAmount(row)}
              deliveryAmount={row.delivery_amount}
            />
          </Paper>
          {
            row.delivery_amount !== 0 &&
              <React.Fragment>
                <br />
                <Paper>
                  <Toolbar>
                    <Typography variant="h6">
                      Customer Information
                    </Typography>
                  </Toolbar>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Name:</TableCell>
                        <TableCell>
                          { row.contact_name }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone:</TableCell>
                        <TableCell>
                          { row.contact_phone }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Address:</TableCell>
                        <TableCell>
                          { row.address }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Post Code:</TableCell>
                        <TableCell>
                          { row.post_number }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Plan TIme:</TableCell>
                        <TableCell>
                          { formatDatetime(row.plan_datetime) }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </React.Fragment>
          }
          <br />
          <Paper>
            <Toolbar>
              <Typography variant="h6">
                Items
              </Typography>
            </Toolbar>
            <OrderItemTable
              skuset={get(row, 'ordersku_set', [])}
              created={row.created}
            />
            {
              isShowExtraTable(row) &&
              <React.Fragment>
                <br />
                <Toolbar>
                  <Typography variant="h6">
                    Extras
                  </Typography>
                </Toolbar>
                <OrderExtraTable
                  skuset={get(row, 'ordersku_set', [])}
                />
              </React.Fragment>
            }
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Cencel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InfoModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.shape({}).isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

InfoModal.defaultProps = {
  open: false,
  handleClose: () => null,
};

export default withMobileDialog()(withStyles(styles)(InfoModal));
